<template>
  <div class="footer-div">
    <div class="container py-5">
      <div class="row">
        <div class="col-12 col-md text-center">
          <!-- <img class="footer-logo-img" src="@/assets/images/logo.png" alt=""><br><br> -->
          <small class="d-block mb-2 text-muted">&copy; 삼성청년SW아카데미 5기 서울캠퍼스 4반 3팀 떡잎방범대 🌱</small>
          <small class="d-block mb-2 text-muted">당현아 | 안상훈 | 이상현 | 이영주 | 정원석</small>
        </div>
        <!-- <div class="col-6 col-md">

        </div>
        <div class="col-6 col-md">
          <h5>Resources</h5>
          <ul class="list-unstyled text-small">
            <li><a class="link-secondary" href="#">Resource name</a></li>
            <li><a class="link-secondary" href="#">Resource</a></li>
            <li><a class="link-secondary" href="#">Another resource</a></li>
            <li><a class="link-secondary" href="#">Final resource</a></li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>Resources</h5>
          <ul class="list-unstyled text-small">
            <li><a class="link-secondary" href="#">Business</a></li>
            <li><a class="link-secondary" href="#">Education</a></li>
            <li><a class="link-secondary" href="#">Government</a></li>
            <li><a class="link-secondary" href="#">Gaming</a></li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>About</h5>
          <ul class="list-unstyled text-small">
            <li><a class="link-secondary" href="#">Team</a></li>
            <li><a class="link-secondary" href="#">Locations</a></li>
            <li><a class="link-secondary" href="#">Privacy</a></li>
            <li><a class="link-secondary" href="#">Terms</a></li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoFooter",
}
</script>

<style>
.footer-div{
  background: #f7f7f7;
}

.link-secondary {
  text-decoration: none;
}

.footer-logo-img{
  width: 50px;
  height: 50px;
}
</style>